<template>
  <div>
    <h4 class="text-12 font-semibold">
      Select account verification method?
    </h4>

    <div class="mb-6">
      <template v-for="(option, index) in options">
        <verification-option
          :key="index"
          :is-selected="option == selectedOption"
          :option="option"
          @click.native.prevent="selectOption(option)"
        />
      </template>
    </div>

    <!-- Work Email -->
    <WorkEmail />
    <!-- Bank Statement -->


    <!-- <div class="mt-12 mb-6">
      <h4 class="text-12 font-semibold">
        Select account verification method?
      </h4>
      <div
        class="w-full p-5 mt-3 rounded-sm flex flex-row transition duration-200 cursor-pointer"
        :class="[
          verifyWith === 'email' ? 'bg-corporate-800' : 'bg-corporate-900',
          bankStatementUploaded ? 'hidden' : 'block',
        ]"
        @click="verifyWith = 'email'"
      >
        <div
          class="rounded-full mt-1 h-8 w-8 items-center flex justify-center"
          :class="verifyWith == 'email' ? 'bg-white' : 'bg-gray-300'"
        >
          <svg
            width="10"
            height="9"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            :class="verifyWith == 'email' ? 'block' : 'hidden'"
          >
            <path
              d="M1 5.08333L3.94118 8L11 1"
              stroke="#5571FF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          class="flex flex-col ml-8"
          :class="verifyWith == 'email' ? 'text-white' : 'text-black'"
        >
          <h4 class="text-12 mt-1" style="font-weight: 500">
            Verify with workplace email
          </h4>
          <h4 class="text-10 font-thin mt-3">
            We will send an OTP to the customer's workplace email.
          </h4>
        </div>
      </div>

      <div
        class="w-full p-5 mt-3 rounded-sm flex flex-row transition duration-200 cursor-pointer"
        :class="[
          verifyWith == 'statement' || bankStatementUploaded
            ? 'bg-corporate-800'
            : 'bg-corporate-900',
          emailVerificationForm.sent ? 'hidden' : 'block',
        ]"
        @click="verifyWith = 'statement'"
      >
        <div
          class="rounded-full mt-1 h-8 w-8 items-center flex justify-center"
          :class="
            verifyWith == 'statement' || bankStatementUploaded
              ? 'bg-white'
              : 'bg-gray-300'
          "
        >
          <svg
            width="10"
            height="9"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            :class="
              verifyWith == 'statement' || bankStatementUploaded
                ? 'block'
                : 'hidden'
            "
          >
            <path
              d="M1 5.08333L3.94118 8L11 1"
              stroke="#5571FF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          class="flex flex-col ml-8"
          :class="
            verifyWith == 'statement' || bankStatementUploaded
              ? 'text-white'
              : 'text-black'
          "
        >
          <h4 class="text-12 mt-1" style="font-weight: 500">
            Automatic bank statement verification
          </h4>
          <h4 class="text-10 font-thin mt-3">
            Bank statement can be verified in minutes without uploading
          </h4>
        </div>
      </div>
    </div> -->

    <!-- <div class="mb-6" v-if="email">
      <h3 class="text-12 mb-2">Work email address</h3>
      <div class="flex mb-2">
        <div class="w-full">
          <template v-if="emailVerificationForm.sent">
            <form-group
              id="workplace_email"
              name="workplace_email"
              :form="form"
              v-model="form.data.workplace_email.value"
            >
              Enter work email address
            </form-group>
          </template>
          <template v-else-if="!emailVerificationForm.skipped">
            <form-group
              id="workplace_email"
              name="workplace_email"
              :form="emailVerificationForm"
              v-model="emailVerificationForm.data.workplace_email.value"
            >
              Enter work email address
            </form-group>
          </template>
          <div>
            <button
              type="button"
              class="inline-block bg-blue-200 focus:outline-none rounded-sm text-2xs font-normal px-6 pb-2 py-3 -mt-4 cursor-pointer"
              v-if="emailVerificationForm.skipped"
              @click="hasWorkPlaceEmail"
            >
              I have a work email!
            </button>
          </div>
        </div>

        <div class="ml-2" v-if="!emailVerificationForm.skipped">
          <template v-if="emailVerificationForm.sent && !emailOtpTimer.done">
            <button
              type="button"
              class="btn btn-blue font-bold text-2xs whitespace-no-wrap h-50px"
              :disabled="true"
            >
              <span>Verify Email</span>
            </button>
          </template>
          <template v-else-if="!emailVerificationForm.skipped">
            <button
              type="button"
              class="btn btn-blue font-bold text-2xs whitespace-no-wrap h-50px"
              @click="verifyEmail"
              :disabled="emailVerificationForm.loading"
            >
              <span v-if="emailVerificationForm.loading">Verifying</span>
              <span v-else>Verify Email</span>
            </button>
          </template>
          <div
            class="text-2xs flex items-center text-blue-500 mt-2"
            :class="{ 'opacity-50': emailVerificationForm.sent }"
          >
            <ion-icon name="alert-circle" class="mr-1 text-xl" />
            <span style="margin-top: 2px" v-if="!emailOtpTimer.done">
              OTP Sent | Resend in {{ emailOtpTimer.time }}.
            </span>
            <span style="margin-top: 2px" v-else>
              Click to continue
            </span>
          </div>
        </div>
      </div>

      <template v-if="getFormError(emailVerificationForm)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(emailVerificationForm) }}</span>
        </div>
      </template>
    </div> -->

    <!-- <template>
      <div class="mb-6 mt-12" v-if="statement">
        <h3 class="text-12 mb-2">Automatic Bank statement Verification</h3>
        <div
          class="w-full p-5 rounded-sm bg-corporate-900 flex-row transition"
        >
          <h3 class="text-10 mb-2 mt-1 font-thin">
            To verify kindly select the customer's salary / income bank account
          </h3>
          <div class="mt-4 mb-3">
            <div class="relative mb-3">
              <select
                class="block appearance-none w-full bg-white text-12 text-black py-4 px-4 pr-8 rounded-sm leading-tight focus:outline-none"
                @change="verifyBank"
                v-model="bankName"
                :disabled="bankStatementUploaded"
              >
                <option
                  v-for="(option, i) in bankOptions"
                  :value="option.value"
                  :name="option.name"
                  :key="i"
                  >{{ option.value }}</option
                >
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <h3
            class="mt-8 font-normal text-11 mb-2"
            v-if="checkMbs"
            :class="monoVerified || okraVerified || manualVerified ? 'hidden' : 'block'"
          >
            The customer might be charge by their bank for this service.
          </h3>
          <div
            class="w-full p-5 mt-2 rounded-sm cursor-pointer flex bg-corporate-900 border justify-between transition duration-200"
            :class="[
              methodSelected === 'MBS'
                ? 'border-onboarding-600'
                : 'border-onboarding-800',
              monoVerified || okraVerified || manualVerified ? 'hidden' : 'block',
            ]"
            @click.prevent="
              $refs.verifyMBS.open();
              methodSelected = 'MBS';
            "
            v-if="checkMbs"
          >
            <div class="flex flex-col text-onboarding-600 mb-2">
              <h4 class="text-12 mt-2 font-bold">Verify with MBS</h4>
              <h4 class="text-10 mt-1 font-thin">
                Provide basic bank information to continue.
              </h4>
            </div>
            <div
              class="rounded-full mt-1 h-8 w-8 items-center flex justify-center"
              :class="
                methodSelected === 'MBS' || mbsVerified
                  ? 'bg-credpal-400'
                  : 'bg-onboarding-800'
              "
            >
              <svg
                width="5"
                height="10"
                viewBox="0 0 5 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L4 5L1 9"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>

          <h3
            class="mt-5 text-11 font-normal mb-2"
            v-if="checkMono || checkOkra"
            :class="mbsVerified ? 'hidden' : 'block'"
          >
            Free Options
          </h3>
          <mono :publicKey="publicKey" @success="success">
            <div
              class="w-full p-5 mt-3 rounded-sm bg-corporate-900 border cursor-pointer flex justify-between transition duration-200"
              @click="methodSelected = 'Mono'"
              :class="[
                methodSelected === 'Mono'
                  ? 'border-onboarding-600'
                  : 'border-onboarding-800',
                mbsVerified || okraVerified || manualVerified ? 'hidden' : 'block',
              ]"
              v-if="checkMono"
            >
              <div class="flex flex-col text-onboarding-600 mb-2">
                <h4 class="text-12 mt-2 font-bold">Verify with Mono</h4>
                <h4 class="text-10 mt-1 font-thin">
                  Provide basic bank information to continue.
                </h4>
              </div>
              <div
                class="rounded-full mt-1 h-8 w-8 items-center flex justify-center"
                :class="
                  methodSelected === 'Mono' || monoVerified
                    ? 'bg-credpal-400'
                    : 'bg-onboarding-800'
                "
              >
                <svg
                  width="5"
                  height="10"
                  viewBox="0 0 5 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L4 5L1 9"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </mono>
          <okra @success="okraSuccess">
            <div
              class="w-full p-5 mt-2 rounded-sm bg-corporate-900 border cursor-pointer flex justify-between transition duration-200"
              @click="methodSelected = 'Okra'"
              :class="[
                methodSelected === 'Okra'
                  ? 'border-onboarding-600'
                  : 'border-onboarding-800',
                monoVerified || mbsVerified || manualVerified ? 'hidden' : 'block',
              ]"
              v-if="checkOkra"
            >
              <div class="flex flex-col text-onboarding-600 mb-2">
                <h4 class="text-12 mt-2 font-bold">Verify with Okra</h4>
                <h4 class="text-10 mt-1 font-thin">
                  Provide basic bank information to continue.
                </h4>
              </div>
              <div
                class="rounded-full mt-1 h-8 w-8 items-center flex justify-center"
                :class="
                  methodSelected === 'Okra' || okraVerified
                    ? 'bg-credpal-400'
                    : 'bg-onboarding-800'
                "
              >
                <svg
                  width="5"
                  height="10"
                  viewBox="0 0 5 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L4 5L1 9"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </okra>

          <div
            class="w-full p-5 mt-2 rounded-sm cursor-pointer bg-onboarding-900 text-white flex bg-corporate-900 border justify-between transition duration-200"
            :class="[mobileVerified || manualVerified ? 'hidden' : 'block']"
            @click.prevent="
              $refs.verifyUSSD.open();
              methodSelected = 'ussd';
            "
            v-if="checkUssd"
          >
            <div class="flex flex-col mb-2">
              <h4 class="text-12 mt-2 font-bold">Verify with USSD</h4>
              <h4 class="text-10 mt-1 font-thin">
                Use USSD Banking to send their bank statement.
              </h4>
            </div>
            <div
              class="rounded-full mt-1 h-8 w-8 items-center bg-gtb flex justify-center"
            >
              <svg
                width="5"
                height="10"
                viewBox="0 0 5 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L4 5L1 9"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>

          <div
            class="w-full p-5 mt-2 rounded-sm cursor-pointer bg-onboarding-900 text-white flex border justify-between transition duration-200"
            :class="[ussdVerified || manualVerified ? 'hidden' : 'block']"
            @click.prevent="
              methodSelected = 'mobile';
              $refs.verifyMobile.open();
            "
            v-if="checkMobile"
          >
            <div class="flex flex-col mb-2">
              <h4 class="text-12 mt-2 font-bold">Verify with Mobile App</h4>
              <h4 class="text-10 mt-1 font-thin">
                Use Mobile Banking App to send thier bank statement.
              </h4>
            </div>
            <div
              class="rounded-full mt-1 h-8 w-8 bg-gtb items-center flex justify-center"
            >
              <svg
                width="5"
                height="10"
                viewBox="0 0 5 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L4 5L1 9"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>

          <div
            :class="
              mbsVerified || monoVerified || okraVerified ? 'hidden' : 'block'
            "
          >
            <h3 class="mt-5 text-12 mb-2" v-if="bankName">Manual</h3>

            <h3 class="mt-2 font-normal text-11 mb-2" v-if="bankName">
              Can't find their bank? Use manual upload.
            </h3>
          </div>
          <div
            class="w-full p-5 mt-2 rounded-sm cursor-pointer flex bg-corporate-900 border justify-between transition duration-200"
            @click="
              $refs.uploadBankStatement.open();
              methodSelected = 'upload';
            "
            :class="[
              methodSelected === 'upload'
                ? 'border-onboarding-600'
                : 'border-onboarding-800',
              mbsVerified ||
              monoVerified ||
              okraVerified ||
              ussdVerified ||
              mobileVerified
                ? 'hidden'
                : 'block',
            ]"
            v-if="bankUpload"
          >
            <div class="flex flex-col text-onboarding-600 mb-2">
              <h4 class="text-12 mt-2 font-bold">
                Verify manually with Bank Statement
              </h4>
              <h4 class="text-10 mt-1 font-thin">
                Provide a 6 months bank statement instead.
              </h4>
            </div>
            <div
              class="rounded-full mt-1 h-8 w-8 items-center flex justify-center"
              :class="
                methodSelected === 'upload'
                  ? 'bg-credpal-400'
                  : 'bg-onboarding-800'
              "
            >
              <svg
                width="5"
                height="10"
                viewBox="0 0 5 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L4 5L1 9"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </template> -->

    <!-- <template
      v-if="
        emailVerificationForm.sent ||
          (emailVerificationForm.skipped)
      "
    >
      <div v-scroll-to>
        <div
          class="flex items-center mb-5"
          v-if="emailVerificationForm.skipped"
        >
          <div class="alert-icon text-red-500 bg-red-200">!</div>
          <span class="text-xs text-red-500">
            Kindly note lack of work email reduces their chances of
            approval.
          </span>
        </div>

        <form-group
          id="emailOtp"
          name="emailOtp"
          :form="form"
          v-model="form.data.emailOtp.value"
          ref="emailOtp"
          v-if="emailVerificationForm.sent"
        >
          Enter the OTP sent to the customer's work email
        </form-group>
      </div>
    </template> -->

    <modal className="w-11/12 sm:w-400px rounded lg:mr-300px" ref="verifyMBS">
      <div class="md:-mx-8">
        <h1 class="text-18 text-left font-bold mb-3">
          Verify with MBS
        </h1>
        <div class="p-3 rounded-sm bg-onboarding-200 flex flex-row mb-5">
          <div class="w-10 mr-5">
            <svg
              width="53"
              height="56"
              viewBox="0 0 53 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d)">
                <path
                  d="M25.688 39.5931C26.2015 39.864 26.7985 39.864 27.312 39.5931C29.776 38.293 37 33.9721 37 28V21.0286C37 20.174 36.457 19.4138 35.6486 19.1367L27.1486 16.2224C26.7282 16.0782 26.2718 16.0782 25.8514 16.2224L17.3514 19.1367C16.543 19.4138 16 20.174 16 21.0286V28C16 33.9721 23.224 38.293 25.688 39.5931Z"
                  fill="white"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d"
                  x="0"
                  y="0.115234"
                  width="53"
                  height="55.682"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="8" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0.404167 0 0 0 0 0.262215 0 0 0 0.32 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <form @submit.prevent="submitMBS">
          <template v-if="getFormError(mbsform)">
            <div class="alert alert-red-soft mb-10">
              <span class="alert-icon">!</span>
              <span>{{ getFormError(mbsform) }}</span>
            </div>
          </template>

          <form-group
            leftIcon="mail-outline"
            name="bank_name"
            :form="mbsform"
            v-model="mbsform.data.bank_name.value"
            disabled
          >
            Bank Name
          </form-group>

          <form-group
            leftIcon="mail-outline"
            name="account_no"
            :form="mbsform"
            v-model="mbsform.data.account_no.value"
          >
            Account No
          </form-group>

          <form-group
            type="phone"
            leftIcon="call-outline"
            name="phone"
            :form="mbsform"
            v-model="mbsform.data.phone.value"
          >
            Phone Number Attached To Bank Account
          </form-group>

          <div class="flex justify-center">
            <button
              class="btn btn-blue font-normal py-4 px-16"
              type="submit"
              :disabled="mbsform.loading || bankStatementUploaded"
            >
              <sm-loader class="sm-loader-white" v-if="mbsform.loading" />
              <span v-else>Submit</span>
            </button>
          </div>
        </form>
      </div>
    </modal>

    <modal
      className="w-11/12 sm:w-400px rounded lg:mr-300px"
      ref="uploadBankStatement"
    >
      <div class="lg:-mx-8">
        <h1 class="text-18 text-left font-bold mb-2">
          Verify with Bank Statement
        </h1>
        <p class="text-12 mb-3 text-ash-700">
          Upload a 6-month statement of their bank account.
        </p>

        <div class="mt-10">
          <h1 class="text-12 text-left font-bold mb-2">
            Bank Statement
          </h1>

          <template v-if="getFormError(bankStatementForm)">
            <div class="alert alert-red-soft mb-10">
              <span class="alert-icon">!</span>
              <span>{{ getFormError(bankStatementForm) }}</span>
            </div>
          </template>

          <div class="flex flex-col">
            <dropzone
              class="mb-0 mr-2 w-full"
              v-model="bankStatementForm.data.bank_statement.value"
              accept=".pdf"
            />
            <div class="p-2 rounded-sm text-9 mt-3 bg-onboarding-300">
              <span class="text-ash-700 ml-3">
                Their bank statement would determine their increased limit.
              </span>
            </div>
            <button
              type="button"
              class="btn btn-blue py-5 mx-16 mt-32"
              @click.prevent="submitBankStatement"
              :disabled="bankStatementForm.loading || bankStatementUploaded"
            >
              <sm-loader
                class="sm-loader-white"
                v-if="bankStatementForm.loading"
              />
              <span class="text-sm" v-else>Submit</span>
            </button>

            <div
              class="form-group-2-text text-red-500 mb-2"
              v-if="getFirstError('bank_statement', bankStatementForm)"
            >
              {{ getFirstError("bank_statement", bankStatementForm) }}
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal className="w-11/12 sm:w-400px rounded lg:mr-300px" ref="verifyUSSD">
      <div class="lg:-mx-8">
        <h1 class="text-18 text-left font-bold mb-2">
          Verify with USSD
        </h1>

        <div class="rounded-sm bg-onboarding-900 text-white flex flex-row p-3">
          <div class="rounded-full h-6 w-6 bg-gtb"></div>
          <p class="text-10 mt-1 ml-3">
            Follow the steps below to verify the customer's account.
          </p>
        </div>

        <div
          class="rounded-sm bg-gtbonboarding-100 text-center mt-4 pt-4 h-100px"
        >
          <p class="text-12">Dial</p>
          <h4 class="text-onboarding-900 text-32 mt-2">*737*51*33#</h4>
        </div>

        <div
          class="rounded-sm bg-gtbonboarding-200 mt-4 pt-4 text-center h-85px"
        >
          <p class="text-12">Then enter the email address below</p>
          <h4 class="text-blue-500 text-16 mt-3">gtb@credpal.com</h4>
        </div>

        <div class="flex justify-center mt-12">
          <button
            type="button"
            class="btn btn-blue py-4 px-14"
            @click="SubmitGtbMethod('ussd')"
          >
            <span class="text-sm">I've done it</span>
          </button>
        </div>
      </div>
    </modal>

    <modal
      className="w-11/12 sm:w-400px rounded lg:mr-300px"
      ref="verifyMobile"
    >
      <div class="lg:-mx-8">
        <h1 class="text-18 text-left font-bold mb-2">
          Verify with Mobile App
        </h1>

        <div class="rounded-sm bg-onboarding-900 text-white flex flex-row p-3">
          <div class="rounded-full h-6 w-6 bg-gtb"></div>
          <p class="text-10 mt-1 ml-3">
            Follow the steps below to verify the customer's account.
          </p>
        </div>

        <GtbMobile />

        <div class="flex justify-center mt-12">
          <button
            type="button"
            class="btn btn-blue py-4 px-14"
            @click="SubmitGtbMethod('mobile')"
          >
            <span class="text-sm">I've done it</span>
          </button>
        </div>
      </div>
    </modal>

    <modal
      className="w-11/12 sm:w-400px rounded lg:mr-300px text-center"
      ref="successModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-14">
        Success!
      </div>

      <div class="text-11 text-left font-semibold mb-4">
        Bank Statement Request Process Initiated
      </div>
      <div class="text-11 text-left font-normal mt-4 mb-4">
        Please note a passcode will be sent to the customer bank to authorize access to
        the bank statement request.
      </div>
      <div class="text-11 text-left font-normal mt-4 mb-4">
        Contact the customer for the passcode.
      </div>

      <div class="flex justify-center">
        <button
          type="button"
          class="btn btn-blue py-3 mt-6 px-16"
          @click.prevent="$refs.successModal.close()"
        >
          Okay
        </button>
      </div>
    </modal>

    <modal
      className="w-11/12 sm:w-400px rounded lg:mr-300px text-center"
      ref="bankStatementUploaded"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-14">
        Success!
      </div>

      <div class="text-11 font-semibold mb-4">
        Bank Statement Uploaded Successfully
      </div>

      <div class="flex justify-center">
        <button
          type="button"
          class="btn btn-blue py-3 mt-6 px-16"
          @click.prevent="$refs.bankStatementUploaded.close()"
        >
          Okay
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    availableOptions: {
      type: Array,
      default: () => ['work_email', 'bank_statement']
    }
  },
  components: {
    VerificationOption: () => import('../VerificationOption'),

    // Work Email
    WorkEmail: () => import('./WorkEmail'),
    // GtbMobile: () => import("@/views/auth/components/GtbMobile.vue"),
    // MBS
    // Bank Statement
  },
  data() {
    return {
      selectedOption: null,
      verified: false,
      bankName: '',
      checkMono: false,
      checkMbs: false,
      checkUssd: false,
      checkMobile: false,
      checkOkra: false,
      bankUpload: false,
      methodSelected: "",
      verifyWith: "",
      bankStatementForm: this.$options.basicForm([
        { name: "bank_statement", value: null },
      ]),
      errorMessage: {},
    }
  },
  computed: {
    ...mapState(["banks", "mbsBanks", "okraBanks"]),
    options() {
      return this.availableOptions?.map(option => {
        switch (option) {
          case 'work_email':
            return {
              label: 'Verify with workplace email',
              description: 'We will send an OTP to the customer\'s workplace email.'
            };
          case 'bank_statement':
            return {
              label: 'Automatic bank statement verification',
              description: 'Bank statement can be verified in minutes without uploading.'
            };
        }
      })
    },
    canSubmitForm() {
      return (
        this.emailVerificationForm.sent ||
        this.emailVerificationForm.skipped ||
        this.bankStatementUploaded
      );
    },
    email() {
      return this.verifyWith === "email";
    },
    statement() {
      return this.verifyWith === "statement";
    },
    bankOptions() {
      return this.banks.length
        ? this.banks.map((bank) => ({
            title: bank.name,
            value: bank.name,
          }))
        : [];
    },
    bankStatementUploaded() {
      return this.selectedUser?.documents?.find(
        (document) => document.type == "bank_statement"
      );
    },
    monoVerified() {
      return this.selectedUser?.documents?.find((document) => document.url === "mono");
    },
    mbsVerified() {
      return this.selectedUser?.documents?.find((document) => document.url === "mbs");
    },
    okraVerified() {
      return this.selectedUser?.documents?.find((document) => document.url === "okra");
    },
    ussdVerified() {
      return this.selectedUser?.documents?.find((document) => document.url === "ussd");
    },
    mobileVerified() {
      return this.selectedUser?.documents?.find(
        (document) => document.url === "mobile"
      );
    },
    manualVerified() {
      return (
        this.bankStatementUploaded &&
        !this.monoVerified &&
        !this.mbsVerified &&
        !this.okraVerified &&
        !this.ussdVerified &&
        !this.mobileVerified
      );
    },
    publicKey() {
      return "live_pk_d2X0CSU0qIZX9AH1ZwXV";
    },
  },
  mounted() {
    this.$options.basicWatchers(this);
  },
  watch: {
    "emailVerificationForm.data.workplace_email.value"(value) {
      this.form.data.workplace_email.value = value;
    },
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
      this.$emit('select-option', option);
    },
    verifyBank() {
      this.mbsform.data.bank_name.value = this.bankName;
      if (this.bankName === "Guaranty Trust Bank") {
        this.checkUssd = true;
        this.checkMobile = true;
        this.bankUpload = true;
        this.checkMbs = false;
        this.checkOkra = false;
        this.checkMono = false;
      } else if (
        this.mbsBanks.includes(this.bankName) &&
        this.okraBanks.includes(this.bankName)
      ) {
        this.checkMbs = true;
        this.checkMono = true;
        this.checkOkra = true;
        this.bankUpload = true;
        this.checkUssd = false;
        this.checkMobile = false;
      } else if (this.mbsBanks.includes(this.bankName)) {
        this.checkMbs = true;
        this.checkMono = true;
        this.checkOkra = false;
        this.bankUpload = true;
        this.checkUssd = false;
        this.checkMobile = false;
      } else if (this.okraBanks.includes(this.bankName)) {
        this.checkMono = true;
        this.checkOkra = true;
        this.checkMbs = false;
        this.bankUpload = true;
        this.checkUssd = false;
        this.checkMobile = false;
      } else {
        this.bankUpload = true;
        this.checkMono = false;
        this.checkMbs = false;
        this.checkOkra = false;
        this.checkUssd = false;
        this.checkMobile = false;
      }
    },
    async submitMBS() {
      this.mbsform.loading = true;

      await this.$post({
        url: `${this.$baseurl}/register-process/v1/bank-statement/mbs`,
        headers: this.headers,
        data: this.getFormData(this.mbsform),
        success: (response) => {
          this.$emit("document", { user: response.data.user });
          this.resetForm("mbsform");
          this.$refs.verifyMBS.close();
          this.$refs.successModal.open();
        },
        error: (error) => {
          this.mbsform.error = error;
          this.mapFormErrors(this.mbsform, error?.response?.data?.errors);
        },
      });
      this.mbsform.loading = false;
    },
    async success(code) {
      await this.$put({
        url: `${this.$baseurl}/register-process/v1/bank-statement/mono`,
        headers: this.headers,
        data: { code: code },
        success: (response) => {
          this.$emit("document", { user: response.data.user });
        },
        error: (error) => {
          if (error?.response?.data?.success == false) {
            this.errorMessage = error?.response?.data?.message;
          }
        },
      });
    },
    async okraSuccess({ clientId }) {
      await this.$put({
        url: `${this.$baseurl}/register-process/v1/bank-statement/okra`,
        headers: this.headers,
        data: { code: clientId },
        success: (response) => {
          this.$emit("document", { user: response.data.user });
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
    async SubmitGtbMethod(type) {
      await this.$get({
        url: `${this.$baseurl}/register-process/v1/bank-statement/${type}`,
        headers: this.headers,
        success: (response) => {
          this.$emit("document", { user: response.data.user });
          this.$refs.verifyMobile.close();
          this.$refs.verifyUSSD.close();
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
    async submitBankStatement() {
      this.googleEvent("Sign Up", "bank_statement");

      if (!this.validateForm(this.bankStatementForm)) {
        return false;
      }
      const data = new FormData();

      data.append(
        "bank_statement",
        this.bankStatementForm.data.bank_statement.value
      );

      this.bankStatementForm.loading = true;
      this.bankStatementForm.error = false;

      await this.sendRequest("registration.documents", {
        data,
        success: (response) => {
          this.$refs.bankStatementUploaded.open();
          this.$refs.uploadBankStatement.close();
          this.$emit("document", { user: response.data.user });
        },
        error: (error) => {
          this.bankStatementForm.error = error;
          this.mapFormErrors(
            this.bankStatementForm,
            error?.response?.data?.errors
          );
        },
      });

      this.bankStatementForm.loading = false;
    },
  }
}
</script>
